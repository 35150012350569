import React, { useEffect, useRef, useState } from 'react';
import './CarouselComponent.css'; // Import the CSS
import { Link } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import EventAnnouncement from './EventAnnouncement'; // Import the EventAnnouncement component
//{isMobile && <EventAnnouncement />}

function CarouselComponent() {
  const videoContainerRef = useRef(null);
  const [videoHeight, setVideoHeight] = useState(0); // Initialize video height
  const isMobile = useMediaQuery('(max-width:600px)'); // Check if it's a mobile screen

  useEffect(() => {
    const videoContainer = videoContainerRef.current;
    const iframe = videoContainer.querySelector('.youtube-iframe');

    const resizeElements = () => {
      const aspectRatio = 16 / 9; // YouTube video's aspect ratio (16:9)
      const width = videoContainer.offsetWidth; // Get the container's width
      const height = width / aspectRatio; // Calculate the appropriate height

      // Set the iframe's width and height
      iframe.width = width;
      iframe.height = height;

      // Update the video height state
      setVideoHeight(height);
    };

    resizeElements(); // Initial resizing
    window.addEventListener('resize', resizeElements); // Resize elements on window resize

    return () => {
      window.removeEventListener('resize', resizeElements); // Remove event listener on component unmount
    };
  }, []);

  return (
    <div className="video-container2" ref={videoContainerRef}>
      {/* Render the EventAnnouncement component only on mobile screens */}

      {/* Text overlays on the video */}
      <div className="overlay-container">
          <div className="text-overlay">
            <h3 className="responsive-text">The International Fair and Forum for Technical, Vocational Education, Training, and Career Pathways</h3>
            <h3 className="responsive-text highlight">Third Edition</h3>
            <h3 className="responsive-text highlight">To be Announced Soon</h3>
            <div className="button-group">
              <Link to="/RegisterPage" className="button">
                Register Now
              </Link>
              <Link to="https://edutech-eg.com/wp-content/uploads/2024/04/EduTech-Egypt-2024-catalogue-1.pdf" className="button">
                Download Catalogue
              </Link>
            </div>
          </div>
        </div>

      <iframe
        title="YouTube video player"
        className="youtube-iframe"
        src="https://www.youtube.com/embed/zU-0zhjUNJU?autoplay=1&fs=0&mute=1&loop=1&controls=0&disablekb=1&playlist=zU-0zhjUNJU"
        frameBorder="0"
        allow="autoplay;"
        style={{ minHeight: `${videoHeight}px` }} // Set min-height based on video height
      ></iframe>

    </div>
  );
}

export default CarouselComponent;
