import React from 'react';
import { FaAlignCenter } from 'react-icons/fa';

const PackageCard = ({ name, price, description }) => {
  const cardStyles = {
    backgroundColor: 'darkred',
    color: 'white',
    padding: '20px',
    borderRadius: '10px',
    margin: '10px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    maxWidth: '300px', // Limit the card width
    width: '100%', // Make all cards have the same width
  };

  const nameStyles = {
    fontSize: '24px',
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'center'


  };

  const priceStyles = {
    fontSize: '18px',
    marginBottom: '10px',
  };

  const descriptionStyles = {
    fontSize: '16px',
    margin: '10px 0', // Add margin to separate bullet points
    paddingLeft: '20px', // Indent the bullet points
  };

  const bulletPoints = description.split(', ').map((point, index) => (
    <li key={index} style={descriptionStyles}>
      {point}
    </li>
  ));

  return (
    <div style={cardStyles}>
      <div style={nameStyles}>{name}</div>
      <ul style={{ listStyleType: 'disc' }}>{bulletPoints}</ul>
    </div>
  );
};

const PackagesList = ({ packages }) => {
  const listStyles = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  };

  return (
    <div style={{ color: 'darkred', justifyContent: 'center' }}>
      <h1 style={{ color: 'darkred', justifyContent: 'center', display: 'flex' }}>Packages</h1>

      <div style={listStyles}>
        {packages.map((packages) => (
          <PackageCard
            key={packages.name}
            name={packages.label}
            description={packages.description}
          />
        ))}
      </div>
    </div>
  );
};

export default PackagesList;
