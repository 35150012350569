import React from 'react';

import Header from './Header';

import AgendaSection from './AgendaSection'
import Footer from './Footer'
import HelpButton from './HelpButton';



const AgendaPage = () => {
  return (
    <div className="home-container">
        <Header />
        <AgendaSection />
        <HelpButton/>





      <hr style={{ borderColor: 'darkred', marginTop: 0, borderWidth: '5px' }} />

      <Footer />



    </div>
  );
};

export default AgendaPage;
