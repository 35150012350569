import React from 'react';

import Header from './Header';

import VideoSection from './VideoSection'
import Footer from './Footer'

import HelpButton from './HelpButton';


const SpeakerPage = () => {
  return (
    <div  className="home-container">
        <Header />
        <VideoSection />

         <HelpButton/>


      <hr style={{ borderColor: 'darkred', marginTop: 0, borderWidth: '5px' }} />

      <Footer />



    </div>
  );
};

export default SpeakerPage;
