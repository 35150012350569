import React, { useState, useEffect } from 'react';
import './AgendaSection.css'; // Import your custom styles
import axios from 'axios';
import Box from '@mui/material/Box'; // Import MUI Box
import Select from '@mui/material/Select'; // Import MUI Select
import MenuItem from '@mui/material/MenuItem'; // Import MUI MenuItem
import { createTheme, ThemeProvider } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel'; // Import MUI InputLabel
import { FaDownload } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import Button from '@mui/material/Button';

  const AgendaSection = () => {
  const [years, setYears] = useState([]);
  const [activeYear, setActiveYear] = useState('');
  const [agendaData, setAgendaData] = useState([]);
  const [activeDay, setActiveDay] = useState('Day 1');
  const [activeTime, setActiveTime] = useState('');
  
  const apiKey = process.env.REACT_APP_API_KEY;
  const theme = createTheme({
    palette: {
      primary: {
        main: "#8B0000"
      },
  
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiKey = process.env.REACT_APP_API_KEY; // Load API key from environment variable
        const response = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/agenda', {
          headers: {
            'Content-Type': 'application/json',
            'api-key': apiKey, // Include the API key in the request headers
          },
        });
        const uniqueYears = Array.from(new Set(response.data.map(item => item.year)));
        setYears(uniqueYears);
        setAgendaData(response.data);
        const maxYear = Math.max(...uniqueYears);
        setActiveYear(maxYear.toString());

      } catch (error) {
        console.error('Error fetching agenda data:', error);
      }
    };

    fetchData();
  }, []);

  const handleYearChange = (event) => {
    setActiveYear(event.target.value);
    setActiveDay(''); // Reset active day when changing year
    setActiveTime(''); // Reset active time when changing year
  };

  const handleDayChange = (event) => {
    setActiveDay(event.target.value);
    setActiveTime(''); // Reset active time when changing day
  };

  const handleTimeChange = (event) => {
    setActiveTime(event.target.value);
  };

  const handleWhatsAppShare = () => {
    // Construct PDF URL based on selected filters
    const pdfUrl = "https://edutech-eg.com/wp-content/uploads/2024/04/Forum-Final-Agenda-VF-2024-4-18-2.pdf";
  
    // Open WhatsApp with the PDF URL
    if (pdfUrl) {
      const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(pdfUrl)}`;
      window.open(whatsappUrl, '_blank');
    } else {
      console.error('PDF URL is not available');
    }
  };
  
  const handleDownloadPDF = () => {
    // Construct PDF URL based on selected filters
    const pdfUrl = "https://edutech-eg.com/wp-content/uploads/2024/04/Forum-Final-Agenda-VF-2024-4-18-2.pdf";
  
    // Trigger PDF download
    if (pdfUrl) {
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.setAttribute('download', 'agenda.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error('PDF URL is not available');
    }
  };

  return (
    <div className="agenda-section">
      <div className="section-title-container">
        <div style={{ color: 'darkred' }} className="section-title">
          Agenda
        </div>

        <div style={{display:"flex", justifyContent:"center"}}>
        <ThemeProvider theme={theme}>

          <Button style={{margin:"10px"}} variant="contained" color="success" onClick={handleWhatsAppShare}>Share Agenda on WhatsApp < FaWhatsapp/> </Button>
          <Button style={{margin:"10px"}}  variant="contained" onClick={handleDownloadPDF}>Download Agenda < FaDownload/></Button>
          </ThemeProvider>

        </div>

      {/* Filters */}
      <div style={{ margin: "10px", display: "flex", justifyContent: "center"  }} className="agenda-select-container">
  <ThemeProvider theme={theme}>
    <Box sx={{ minWidth: 120, margin: "5px" }} className="agenda-select">
      <InputLabel htmlFor="year-label">Year</InputLabel>
      <Select
        onChange={handleYearChange}
        displayEmpty
        inputProps={{ 'aria-label': 'Year' }}
        value={activeYear}
      >
        {years.map((year, index) => (
          <MenuItem key={index} value={year}>
            {year}
          </MenuItem>
        ))}
      </Select>
    </Box>
  </ThemeProvider>

  <ThemeProvider theme={theme}>
    <Box sx={{ minWidth: 120, margin: "5px" }} className="agenda-select">
      {activeYear && (
        <>
          <InputLabel htmlFor="day-label">Day</InputLabel>
          <Select
            value={activeDay}
            onChange={handleDayChange}
            displayEmpty
            inputProps={{ 'aria-label': 'Day' }}
          >
            {agendaData
              .filter(item => item.year === activeYear)
              .map(item => item.day)
              .filter((day, index, self) => self.indexOf(day) === index)
              .map((day, index) => (
                <MenuItem key={index} value={day}>
                  {day}
                </MenuItem>
              ))}
          </Select>
        </>
      )}
    </Box>
  </ThemeProvider>


  <ThemeProvider theme={theme}>
    <Box sx={{ minWidth: 120, margin: "5px" }} className="agenda-select">
      {activeYear && activeDay && (
        <>
          <InputLabel htmlFor="time-label">Time</InputLabel>
          <Select
            value={activeTime}
            onChange={handleTimeChange}
            displayEmpty
            inputProps={{ 'aria-label': 'Time' }}
          >
            <MenuItem value="">Select Time</MenuItem>
            {agendaData
              .filter(item => item.year === activeYear && item.day === activeDay)
              .map((item, index) => (
                <MenuItem key={index} value={item.time}>
                  {item.time}
                </MenuItem>
              ))}
          </Select>
        </>
      )}
    </Box>
  </ThemeProvider>
</div>
</div>

      {/* Agenda Content */}
      {activeYear && activeDay  && (

      <div className="agenda-content">
        {agendaData
          .filter(
            (item) =>
              (!activeYear || item.year === activeYear) &&
              (!activeDay || item.day === activeDay) &&
              (!activeTime || item.time === activeTime)
          )
          .map((item, index) => (
            <div key={index} className="agenda-day">
              <div className="timing">
                <div className="timing-label">{item.time}</div>
                <div className="timing-details">
                  <h4 className="white-text">{item.session}</h4>
                  {item.moderator && (
                    <div>
                      <h5 style={{ marginTop: '10px' }} className="white-text">
                        Moderator
                      </h5>
                      <p>{item.moderator}</p>
                    </div>
                  )}
                  {item.speakers.length > 0 && (
                    <div className="speakers-list">
                      <h5 className="speakers-heading white-text">Speakers</h5>
                      <div className="speakers">
                        {item.speakers.map((speaker, speakerIndex) => (
                          <p key={speakerIndex} className="speaker">
                            {speaker}
                          </p>
                        ))}
                      </div>
                    </div>
                  )}
                  {item.description && (
                    <div className="description">
                      <h5 className="white-text">Description</h5>
                      <p>{item.description}</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="line"></div>
            </div>
          ))}
      </div>
      )}
    </div>
  );
};

export default AgendaSection;
