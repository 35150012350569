import React, { useState, useEffect } from 'react';
import FAQItem from './FAQItem';
import axios from 'axios';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './FAQ.css';

const theme = createTheme({
  palette: {
    primary: {
      main: "#8B0000", // Dark Red
    },
  },
});

const FAQ = () => {
  const [faqData, setFaqData] = useState([]);
  const [visibleCount, setVisibleCount] = useState(5);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    const fetchFAQData = async () => {
      try {
        const response = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/FAQ', {
          headers: {
            'Content-Type': 'application/json',
            'api-key': process.env.REACT_APP_API_KEY,
          },
        });
        setFaqData(response.data);
      } catch (error) {
        console.error('Error fetching FAQ data:', error);
      }
    };

    fetchFAQData();
  }, []);

  const handleShowMoreToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="faq-container">
      <h2 className="custom-section-header">Frequently Asked Questions</h2>
      {faqData.slice(0, isExpanded ? faqData.length : visibleCount).map((item, index) => (
        <FAQItem
          key={index}
          question={item.question}
          answer={item.answer}
        />
      ))}
      <ThemeProvider theme={theme}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleShowMoreToggle}
          sx={{ mb: 2 }}
        >
          {isExpanded ? 'Show Less' : 'Show More'}
        </Button>
      </ThemeProvider>
    </div>
  );
};

export default FAQ;
