import React, { useState, useEffect } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './Header.css';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { FaArrowRight } from "react-icons/fa";

function Header() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 850);
  const [activeItem, setActiveItem] = useState(-1);
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 850);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const theme = createTheme({
    palette: {
      primary: { main: "#8B0000" },
    },
  });

  const handleDropdownHover = (index) => setActiveItem(index);
  const toggleNavbar = () => setIsNavCollapsed(!isNavCollapsed);

  return (
    <header className="sticky-top" style={{ backgroundColor: 'white', color: '#000' }}>
      <div className="container">
        <nav className="navbar navbar-expand-md align-items-center">
          <a className="navbar-brand" href="/#">
            <img src="Edutech_logo.png" alt="Logo" style={{ maxWidth: '150px' }} />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleNavbar}
            aria-controls="navbarNav"
            aria-expanded={!isNavCollapsed}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`collapse navbar-collapse ${isNavCollapsed ? '' : 'show'}`} id="navbarNav">
            <ul className="navbar-nav mx-auto">
              <li className="nav-item">
                <a className="nav-link" href="/">Home</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/#info-section">About</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/regpage">Register</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/EventPage">Fair</a>
              </li>
              <li
                className="nav-item dropdown"
                onMouseEnter={() => handleDropdownHover(0)}
                onMouseLeave={() => handleDropdownHover(-1)}
              >
                <a
                  className="nav-link dropdown-toggle"
                  id="forumDropdown"
                  role="button"
                  aria-expanded={activeItem === 0}
                >
                  Forum
                </a>
                <ul className={`dropdown-menu ${activeItem === 0 ? 'show' : ''}`} aria-labelledby="forumDropdown">
                  <li>
                    <a className="dropdown-item" href="/SessionsPage">Sessions</a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/SpeakerPage">Speakers</a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/AgendaPage">Agenda</a>
                  </li>
                </ul>
              </li>
              <li
                className="nav-item dropdown"
                onMouseEnter={() => handleDropdownHover(1)}
                onMouseLeave={() => handleDropdownHover(-1)}
              >
                <a
                  className="nav-link dropdown-toggle"
                  id="reportsDropdown"
                  role="button"
                  aria-expanded={activeItem === 1}
                >
                  Reports
                </a>
                <ul className={`dropdown-menu ${activeItem === 1 ? 'show' : ''}`} aria-labelledby="reportsDropdown">
                  <li>
                    <a className="dropdown-item" href="https://edugate-eg.com/wp-content/uploads/2023/09/EduTech-Egypt-2023-Post-show-report.pdf" target="_blank" rel="noopener noreferrer">
                      2023
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="https://edugate-eg.com/wp-content/uploads/2023/09/Post-show-report-TECH.pdf" target="_blank" rel="noopener noreferrer">
                      2022
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/#contact-section">Contact Us</a>
              </li>
             
            </ul>
            <ThemeProvider theme={theme}>
              <a href="/enquire" className="enquiry-button">
                <Button variant="contained" endIcon={<FaArrowRight />}>
                  Enquire to Exhibit
                </Button>
              </a>
            </ThemeProvider>
          </div>
        
        </nav>
      </div>
      <hr style={{ borderColor: '#8B0000', marginTop: 0, borderWidth: '3px' }} />
    </header>
  );
}

export default Header;
