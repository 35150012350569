import React from 'react';

import Header from './Header';

import Footer from './Footer'

import HelpButton from './HelpButton';
import NewRegistrationForm from './NewRegistrationForm_event';


const RegPage_event = () => {
  
  return (
    <div  className="home-container">
        <NewRegistrationForm />
    </div>
  );
};

export default RegPage_event;
