import React, { useState, useEffect, useRef } from 'react';
import './FigureCard.css';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import EventIcon from '@mui/icons-material/Event';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import LanguageIcon from '@mui/icons-material/Language';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import PublicIcon from '@mui/icons-material/Public';
const FigureCard = ({ label, number }) => {
  const [count, setCount] = useState(0);
  const countRef = useRef(count);
  const figureCardRef = useRef(null);

  useEffect(() => {
    countRef.current = count;
  }, [count]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          const diff = number - countRef.current;

          if (diff > 0) {
            const speed = Math.max(300, Math.min(800, Math.floor(5000 / diff)));

            const interval = setInterval(() => {
              const increment = countRef.current < 9000 ? 1000 : 100;
              const nextCount = Math.min(countRef.current + increment, number);
              setCount(nextCount);
            }, speed);

            return () => clearInterval(interval);
          }
        }
      },
      { threshold: 0.5 }
    );

    if (figureCardRef.current) {
      observer.observe(figureCardRef.current);
    }

    return () => {
      if (figureCardRef.current) {
        observer.unobserve(figureCardRef.current);
      }
    };
  }, [number]);

  const iconMap = {
    Visitors: <Diversity3Icon fontSize="large" style={{ color: '#ffffff', fontSize: '40px' }} />,
    Events: <EventIcon fontSize="large" style={{ color: '#ffffff', fontSize: '40px' }} />,
    Exhibitors: <BusinessCenterIcon fontSize="large" style={{ color: '#ffffff', fontSize: '40px' }} />,
    Countries: <PublicIcon fontSize="large" style={{ color: '#ffffff', fontSize: '40px' }} />,
    Ministries: <AccountBalanceIcon fontSize="large" style={{ color: '#ffffff', fontSize: '40px' }} />,
  };
////test///test///rrr///rrr////rrr//rr///rr
  return (
    <div className="figure-card" ref={figureCardRef}>
      <Paper elevation={3} className="figure-card-paper">
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">
          {iconMap[label]}
          <Typography variant="h1" className="figure-card-count">
            {count > 1000 ? `${(count / 1000).toFixed(0)}k` : count}
          </Typography>
          <Typography variant="h6" className="figure-card-label">
            {label}
          </Typography>
        </Box>
      </Paper>
    </div>
  );
};

export default FigureCard;
