import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { createTheme, ThemeProvider } from '@mui/material/styles';

// Define CSS styles

const apiKey = process.env.REACT_APP_API_KEY;

const formContainerStyles = {
  padding: (theme) => theme.spacing(3),
  background: 'rgba(255, 255, 255, 0.8)',
  borderRadius: (theme) => theme.spacing(1),
  marginBottom: (theme) => theme.spacing(3), // Add margin to separate from the map
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#8B0000"
    },
  }
});//

const formStyles = {
  display: 'flex',
  flexDirection: 'column',
  gap: (theme) => theme.spacing(2),
  padding: (theme) => theme.spacing(2), // Add padding for better styling
};

const buttonStyles = {
  marginTop: (theme) => theme.spacing(2),
};

const convertArabicToEnglish = (input) => {
  const arabicNumbers = '٠١٢٣٤٥٦٧٨٩';
  const englishNumbers = '0123456789';
  let output = '';
  for (let i = 0; i < input.length; i++) {
    const index = arabicNumbers.indexOf(input[i]);
    output += index !== -1 ? englishNumbers[index] : input[i];
  }
  return output;
};

const RegistrationForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '', // Add phoneNumber field
    voucher:"",
    mobile:"",
    title:"",
    organization:"",
    whichsession:"",
    sessionOption: "", // Add sessionOption field

  });

  const [feedback, setFeedback] = useState({
    message: '',
    type: '', // 'success' or 'error'
  });

  const [language, setLanguage] = useState('en'); // Default language is English
  const [isSubmitting, setIsSubmitting] = useState(false);

  const toggleLanguage = () => {
    // Toggle between English and Arabic
    setLanguage(language === 'en' ? 'ar' : 'en');
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
  
    // Convert Arabic numerals to English
    const convertedValue = convertArabicToEnglish(value);
  
    // Determine the sessionOption value based on whichsession

  
    // Update the form data state
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: convertedValue,
    }));
  };
  
  
  
  
  
  

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    // Check if any of the select fields have the default "Select" value
    if (
      formData.whichsession === '' ||
      formData.mobile === ''
    ) {
      setFeedback({
        message: 'Please select an option for all fields.',
        type: 'error',
      });
      return; // Prevent form submission if any field is still set to "Select"
    }

  
    try {
      // Send the form data to the backend API
      console.log(formData)
      const response = await fetch('https://edutech-backend-471f7cb69116.herokuapp.com/api/Forumregistrations', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'api-key': apiKey, // Include the API key in the request headers

        },
        body: JSON.stringify(formData),
      });
      console.log(response)
  
      if (response.status === 201) {
        const responseData = await response.json();

        const pdfPath = responseData.pdfUrl;
        console.log('Received PDF path:', pdfPath);
  
  
        // Set the feedback for successful submission
        setFeedback({
          message: translations[language].successMessage,
          type: 'success',
        });
  
        // Create and trigger a download link for the PDF
        const link = document.createElement('a');
        link.href = pdfPath;
        link.download = 'invitation.pdf';
        link.click();
      } else if(response.status === 400){
        setFeedback({
          message: translations[language].alreadyMessage,
          type: 'error',
        });

      }//else if(response.status === 401){
        //setFeedback({
         // message: translations[language].maxvoucher,
          //type: 'error',
        //});

      //}//else if(response.status === 402){
        //setFeedback({
         // message: translations[language].invalidvoucher,
        //  type: 'error',
       // });

      //}
      
      else {
        // Handle errors from the backend
        setFeedback({
          message: translations[language].errorMessage,
          type: 'error',
        });
      }
    } catch (error) {
      // Handle network errors
      setFeedback({
        message: translations[language].errorMessage,
        type: 'error',
      });
    }
  };

  const [isOtherSelected, setIsOtherSelected] = useState(false);

  const translations = {
    en: {
      other:"Specify Other",
      phoneMessage: "Phone number must be exactly 11 digits.",
      organization:"Organization",
      registrationForm: "Forum Registration ",
      firstNameLabel: "First Name",
      middleNameLabel: "Middle Name",
      lastNameLabel: "Last Name",
      emailLabel: "Email",
      mobileLabel: "Mobile",
      title: "Title",
      voucher:"Voucher Number",
      whichsession: "Select Day",
      submitButtonLabel: "Submit",
      invalidvoucher:"Invalid Voucher. Check the voucher again or contact the support",
      maxvoucher:"You have reached the maximum number of using this voucher",
      successMessage: "Thank you for registering!",
      errorMessage: "An error occurred while processing the form.",
      alreadyMessage:"You have already registered with this Email",
      whichsessions: [
        { value: 'Day1', label: 'Day 1' },
        { value: 'Day2', label: 'Day 2' },
        { value: 'Day1 and Day2', label: 'Day 1 and Day 2' },

      ],
      sessionday1:[
        {value:"Opening Session",label:"Opening Session"},
        {value:"Update on the news of TVET in Egypt",label:"Update on the news of TVET in Egypt"},
        {value:"Labor Mobility / Mobilization/ International Collaboration and Partnerships",label:"Labor Mobility / Mobilization/ International Collaboration and Partnerships"},
        {value:"Sustainable Development and Smart/Green Skills",label:"Sustainable Development and Smart/Green Skills"},
        {value:"All sessions",label:"All sessions"},

      ],
      sessionday2:[
        {value:"Rebranding of TVET in Egypt",label:"Rebranding of TVET in Egypt"},
        {value:"Experience Sharing Session By TVET Graduates and Students",label:"Experience Sharing Session By TVET Graduates and Students"},
        {value:"Closing Session",label:"Closing Session"},
        {value:"All sessions",label:"All sessions"},

      ]
    },
  };
  

  return (
    <div>
      <div
        style={{
          background: `url('https://edugate-eg.com/wp-content/uploads/2023/09/WhatsApp-Image-2023-09-26-at-11.14.44-PM.jpeg')`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          minHeight: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Container component="main" maxWidth="sm">
          <Paper elevation={3} sx={formContainerStyles}>
            <div style={{ color: "darkred", fontSize: "30px", textTransform: "uppercase" }}>
              {translations[language].registrationForm} {/* Use translations */}
            </div>
            <ThemeProvider theme={theme}>
              <form onSubmit={handleSubmit} sx={formStyles}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
       
                  <div style={{ display: 'flex', gap: '16px' }}>
                    <TextField
                      label={translations[language].firstNameLabel}
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      required
                      sx={{ flex: 1 }}
                    />
                    <TextField
                      label={translations[language].lastNameLabel}
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      required
                      sx={{ flex: 1 }}
                    />
                  </div>
                  <div style={{ display: 'flex', gap: '16px' }}>
                    <TextField
                      label={translations[language].mobileLabel}
                      name="mobile"
                      type="tel"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                      sx={{ flex: 1 }}
                      inputProps={{ pattern: '^\\+?\\d{1,3}?[-.\\s]?\\(?\\d{3}\\)?[-.\\s]?\\d{3}[-.\\s]?\\d{4}$' }}
                    />
                    <TextField
                      label={translations[language].emailLabel}
                      name="email"
                      type="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                      sx={{ flex: 1 }}
                    />
                  </div>
                  <div style={{ display: 'flex', gap: '16px', marginBottom:"16px" }}>
                    <TextField
                      label={translations[language].organization}
                      name="organization"
                      value={formData.organization}
                      onChange={handleChange}
                      required
                      sx={{ flex: 1 }}
                    />
                    <TextField
                      label={translations[language].title}
                      name="title"
                      value={formData.title}
                      onChange={handleChange}
                      required
                      sx={{ flex: 1 }}
                    />
                       
                  </div>
               
                </div>
  
                <FormControl fullWidth sx={{ marginBottom: '20px', display: 'flex', m: "1" }}>
                  <InputLabel id="eduLevel-label">
                    {translations[language].whichsession}
                  </InputLabel>
                  <Select
                    labelId="eduLevel-label"
                    id="whichsession"
                    name="whichsession"
                    value={formData.whichsession}
                    onChange={handleChange}
                    required
                    sx={{ flex: 1}}
                    label={translations[language].whichsession}
                  >
                    {translations[language].whichsessions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {formData.whichsession && formData.whichsession != "Day1 and Day2" &&(
  <FormControl fullWidth sx={{ marginBottom: '20px', display: 'flex', m: "1" }}>
    <InputLabel id="session-option-label">Which session interested in?</InputLabel>
    <Select
      labelId="session-option-label"
      id="sessionOption"
      name="sessionOption"
      value={formData.sessionOption}
      onChange={handleChange}
      sx={{ flex: 1 }}
      label="Which session interested in?"
>
{formData.whichsession === 'Day1' ?
  translations[language].sessionday1.map((option) => (
    <MenuItem key={option.value} value={option.value}>
      {option.label}
    </MenuItem>
  )) 
  : formData.whichsession === 'Day2' ? (
    translations[language].sessionday2.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ))
  ) : (
    <MenuItem disabled value="">
      You don't have to choose a session for this option
    </MenuItem>
          )}

</Select>


  </FormControl>
)}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={buttonStyles}
                  disabled={isSubmitting}
                >
                  {translations[language].submitButtonLabel}
                </Button>
                {feedback.message && (
                  <Typography
                    variant="body1"
                    style={{ color: feedback.type === 'error' ? 'red' : 'green' }}
                  >
                    {feedback.message}
                  </Typography>
                )}
              </form>
            </ThemeProvider>
          </Paper>
        </Container>
      </div>
    </div>
  );
  
};

export default RegistrationForm;
