import React, { useEffect, useRef, useState } from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import CardActions from '@mui/material/CardActions';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const objectivesData = [
  '1. Expand awareness of global trends in developing vocational and entrepreneurial skills.',
  '2. Enhance the social perceptions of TVET.',
  '3. Develop competences and entrepreneurial skills to achieve the SDGs needs.',
  '4. Explore the TVET pathways to higher education.',
  '5. Examine the role of international organizations and development partners in TVET reform in Egypt.',
  '6. Understand the importance of new national initiatives with international partners such as "Sector Skills Councils (SSC).',
  '7. Learn about TVET school\'s management mechanisms.',
  '8. Develop internal quality assurance in technical and vocational education and prepare for ETQAAN authority.',
  '9. Explore labor market trends and future employment-related perspectives for TVET graduates.',
  '10. Understand the impact of digital transformation on labor markets and how TVET systems can respond to future demands and required digital skills.',
];

const objectivesImageUrl =
  'https://edugate-eg.com/wp-content/uploads/2023/09/1262-scaled.webp';

const componentsData = [
  {
    name: 'EduTech Forum',
    description:
      "The two-day forum aims to gather educational institutions, international organizations, business leaders, IT firms, and other stakeholders engaged in TVET and skills development in Egypt. Key goals include expanding awareness of global trends, enhancing social perceptions of TVET, and discussing new national initiatives. Attendees include government officials, industry leaders, and international donors.",
    imageUrl:
      'https://edugate-eg.com/wp-content/uploads/2023/09/WhatsApp-Image-2023-09-26-at-11.14.44-PM.jpeg',
  },
  {
    name: 'Exhibition',
    description:
      "The EduTech Fair gathers all TVET stakeholders under one roof for two days, allowing students to explore career pathways and interact with industry experts. Exhibitors include technology schools, vocational centers, and international partners. Visitors range from students to industry leaders.",
    imageUrl:
      'https://edugate-eg.com/wp-content/uploads/2023/09/1747-scaled.webp',
  },
  {
    name: 'Jobs and Career Path',
    description:
      "The fair offers graduates a platform to meet recruiters, submit resumes, and participate in interviews. We provide comprehensive career information, including industry descriptions, qualifications, and salary ranges to help students make informed decisions about their professional journey.",
    imageUrl:
      'https://edugate-eg.com/wp-content/uploads/2023/09/forum-18-scaled.webp',
  },
  {
    name: 'Interactive Stage Activities',
    description:
      "Parallel with the fair, interactive activities will raise awareness of TVET advantages, with successful models and engaging topics presented by international partners and technological universities.",
    imageUrl:
      'https://edugate-eg.com/wp-content/uploads/2023/10/WhatsApp-Image-2023-10-02-at-12.31.10-PM.webp',
  },
  {
    name: 'Honoring TVET Graduates',
    description:
      "A ceremony to honor the top 20 ranked students with appreciation certificates, gifts, and financial rewards from EDUGATE, the Ministry of Education, and other partners.",
    imageUrl:
      'https://edugate-eg.com/wp-content/uploads/2023/09/10A9776-scaled.webp',
  },
];

const theme = createTheme({
  palette: {
    primary: {
      main: "#B22222", // Darker red
    },
    secondary: {
      main: "#FFFFFF",
    },
  },
  typography: {
    fontFamily: "'Roboto', sans-serif",
  },
});

const Home = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [isVisible, setIsVisible] = useState(false);
    const [showMore, setShowMore] = useState(false);

    const toggleShowMore = () => {
      setShowMore(!showMore);
    };
  const componentRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
          observer.unobserve(componentRef.current);
        }
      },
      { threshold: 0.5 }
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => {
      if (componentRef.current) {
        observer.unobserve(componentRef.current);
      }
    };
  }, []);

  const handleOpenModal = (component) => {
    setModalContent(component);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <ThemeProvider theme={theme}>
       <div id="info-section" style={{ padding: '24px', backgroundColor: '#F7F7F7' }}>
        <Card
          style={{
            backgroundColor: '#333',
            color: '#FFF',
            marginBottom: '24px',
            borderRadius: '16px',
            overflow: 'hidden',
          }}
        >
          <Typography
            align="center"
            style={{
              letterSpacing: '1.5px',
              fontSize: '2.5rem',
              fontWeight: 'bold',
              marginBottom: '25px',
              textTransform: 'uppercase',
            }}
          >
            Driving TVET Excellence
          </Typography>
          <div style={{ position: 'relative' }}>
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: `linear-gradient(to bottom right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${objectivesImageUrl}) no-repeat center center/cover`,
                zIndex: 1,
              }}
            />
            <ul
              style={{
                listStyleType: 'none',
                padding: '40px',
                zIndex: 2,
                position: 'relative',
                textAlign: 'left',
              }}
            >
              <TransitionGroup component={null}>
                {objectivesData.slice(0, showMore ? objectivesData.length : 3).map((objective, index) => (
                  <CSSTransition key={index} timeout={300} classNames="fade">
                    <li
                      style={{
                        marginBottom: '5px',
                        padding: '8px 16px',
                        backgroundColor: 'rgba(255, 255, 255, 0.2)',
                        borderRadius: '8px',
                      }}
                    >
                      {objective}
                    </li>
                  </CSSTransition>
                ))}
              </TransitionGroup>
            </ul>
            <div style={{ textAlign: 'center' }}>
              <Button variant="contained" color="secondary" onClick={toggleShowMore} style={{ zIndex: 999, marginBottom:"3px" }}>
                {showMore ? 'Show Less' : 'Read More'}
              </Button>
            </div>
          </div>
        </Card>
        {/* Components Section */}
        <Typography
          align="center"
          style={{letterSpacing:"1.5px",fontSize:"2.5rem",fontWeight:"bold", marginBottom: '40px', textTransform: 'uppercase', color: '#333' }}
        >
          Components of the Forum
        </Typography>
        <div ref={componentRef}>
          {isVisible && (
            <Slider {...settings}>
              {componentsData.map((component, index) => (
                <div key={index} style={{ padding: '0 8px' }}>
                  <Card style={{ margin: '12px', borderRadius: '16px', height: '100%' }}>
                    <CardHeader
                      title={component.name}
                      style={{ backgroundColor: theme.palette.primary.main, color: '#FFF' }}
                    />
                    <CardContent style={{ padding: '0', flex: '1' }}>
                      <img
                        src={component.imageUrl}
                        alt={component.name}
                        style={{
                          width: '100%',
                          height: '200px',
                          objectFit: 'cover',
                          borderTopLeftRadius: '16px',
                          borderTopRightRadius: '16px',
                        }}
                      />
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                        style={{ padding: '16px', textAlign: 'justify', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical' }}
                      >
                        {component.description}
                      </Typography>
                    </CardContent>
                    <CardActions style={{ justifyContent: 'center' }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleOpenModal(component)}
                      >
                        Read More
                      </Button>
                    </CardActions>
                  </Card>
                </div>
              ))}
            </Slider>
          )}
        </div>

        <Modal open={modalOpen} onClose={handleCloseModal}>
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '90%',
              maxWidth: '800px',
              backgroundColor: 'white',
              boxShadow: 24,
              borderRadius: '16px',
              overflowY: 'auto',
              maxHeight: '80vh',
            }}
          >
            <IconButton
              style={{ position: 'absolute', top: '8px', right: '8px', zIndex: 1 }}
              onClick={handleCloseModal}
            >
              <CloseIcon />
            </IconButton>
            <img
              src={modalContent.imageUrl}
              alt={modalContent.name}
              style={{
                width: '100%',
                height: '300px',
                objectFit: 'cover',
                borderTopLeftRadius: '16px',
                borderTopRightRadius: '16px',
              }}
            />
            <div style={{ padding: '24px' }}>
              <Typography variant="h5" style={{ marginBottom: '16px' }}>
                {modalContent.name}
              </Typography>
              <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
                {modalContent.description}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                style={{ marginTop: '16px' }}
                onClick={handleCloseModal}
              >
                Close
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </ThemeProvider>
  );
};

export default Home;
