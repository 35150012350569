import React from 'react';

import Header from './Header';

import Footer from './Footer'

import HelpButton from './HelpButton';
import EnquireForm from './EnquireForm';


const RegPage = () => {
  const mapStyles = {
    width: '100%',
    height: '400px', // Adjust the height as needed
    border: '1px solid #ccc',
  };
// Update the src attribute of the iframe to include the "t=k" parameter for satellite view
const mapSrc = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3453.1577881667654!2d31.418282599999998!3d30.061011299999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14583d66f83959d3%3A0x50aa74e3a1be44b6!2sRoyal%20Maxim%20Palace%20Kempinski%20Cairo!5e0!3m2!1sen!2seg!4v1695574386613!5m2!1sen!2seg&t=k&z=20";

  
  return (
    <div  className="home-container">
        <Header />
        <EnquireForm />


        
         <HelpButton/>


      <hr style={{ borderColor: 'darkred', marginTop: 0, borderWidth: '5px' }} />

      <Footer />



    </div>
  );
};

export default RegPage;
