import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import './BlogDetailPage.css'; // Import your custom CSS file

import HelpButton from './HelpButton';
import { FiArrowLeft } from 'react-icons/fi';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';



const BlogDetailPage = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const theme = createTheme({
    palette: {
      primary: {
        main: "#8B0000"
      },
  
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiKey = process.env.REACT_APP_API_KEY; // Load API key from environment variable
        const response = await axios.get(`https://edutech-backend-471f7cb69116.herokuapp.com/api/blogs`, {
          headers: {
            'Content-Type': 'application/json',
            'api-key': apiKey, // Include the API key in the request headers
          },
        });
        const foundBlog = response.data.find(blog => blog._id === id);
        if (foundBlog) {
          setBlog(foundBlog);
        } else {
          console.error('Blog not found.');
        }
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };
  
    fetchData();
  }, [id]);
  

  if (!blog) {
    return <div>     
                    <ThemeProvider theme={theme}>

       <CircularProgress  />
       </ThemeProvider>

    </div>;
  }

 // const imageUrl = `${process.env.PUBLIC_URL}/${blog.src}`;

  return (
    <div style={{backgroundColor:"darkred"}}>
        
        <div className="blog-detail">
        <hr style={{ borderColor: 'darkred', marginTop: 0, borderWidth: '5px' }} />
        <Link to="/" className="back-button">
          <FiArrowLeft size={50} /> 
        </Link>
        <h2 className="blog-title">{blog.title}</h2>
        <div className="blog-image">
          <img src={blog.src} alt={blog.title} />
        </div>
        <p className="blog-content">{blog.content}</p>
        {/* Display the reference */}
        <ThemeProvider theme={theme}>
            <Button href={blog.reference}>Read Reference</Button>
            </ThemeProvider>

      </div>



      <hr style={{ borderColor: 'darkred', marginTop: 0, borderWidth: '5px' }} />
      <HelpButton />


    </div>
  );
};

export default BlogDetailPage;
