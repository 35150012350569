import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ClientCarouselSection.css';

function ClientCarouselSection() {
  const logos = [
    "https://edugate-eg.com/wp-content/uploads/2024/03/وزارة-الاتصالات.webp",
    "https://edugate-eg.com/wp-content/uploads/2024/03/وزارة-التخطيط.webp",
    "https://edugate-eg.com/wp-content/uploads/2024/03/ابدا.webp",
    "https://edugate-eg.com/wp-content/uploads/2024/03/وزارة-التعليم-العالي.webp",
    "https://edugate-eg.com/wp-content/uploads/2024/03/وزرة-التربية-و-التعليم.webp",
    "https://edugate-eg.com/wp-content/uploads/2024/03/وزارة-العمل.webp",
    "https://edugate-eg.com/wp-content/uploads/2024/03/وزارة-الهجرة.webp",
    "https://edugate-eg.com/wp-content/uploads/2024/03/وزارة-البترول.webp",
    "https://edugate-eg.com/wp-content/uploads/2024/03/التجارة-و-الصناعة.webp",
  ];

  const settings = {
    infinite: true,
    speed: 700,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 560,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '20px',
        },
      },
    ],
  };

  return (
    <section id='clients' className="custom-carousel-section">
      <div className="custom-section-header">
        Our Official Sponsors
      </div>
      <div className="custom-carousel-wrapper">
        <Slider {...settings}>
          {logos.map((logo, index) => (
            <div key={index} className="custom-carousel-item-content">
              <img className="custom-client-logo" src={logo} alt={`Sponsor Logo ${index + 1}`} />
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
}

export default ClientCarouselSection;
