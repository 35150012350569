// ClientCarouselSection.js
import React, { useState } from 'react';
import { Container, Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './PartnerSection.css';

function PartnerSection() {
  const [isCarouselPaused, setIsCarouselPaused] = useState(false);

  const handleCarouselHover = (hovered) => {
    setIsCarouselPaused(hovered);
  };
  
  return (
    <section id='clients' className="client-carousel-section">
      <Container>
        {/* Partners */}
        <div
          className="carousel-section"
          onMouseEnter={() => handleCarouselHover(true)}
          onMouseLeave={() => handleCarouselHover(false)}
        >
          <div className="custom-section-header">
          Our Success Partners</div>
          <Carousel
            interval={null}
            fade={true}
            indicators={false}
            controls={false}
            pause={isCarouselPaused}
          >
            <Carousel.Item>
              <div className="logo-group">
                {/* Add your partner logo images*/}
                <img className="client-logo2" src="https://edugate-eg.com/wp-content/uploads/2023/09/Logos-35-scaled.webp" alt="Sponsor Logo 2" /> 
                <img className="client-logo2" src="https://edugate-eg.com/wp-content/uploads/2023/09/Logos-34-scaled.webp" alt="Partner Logo 1" />
                <img className="client-logo2" src="https://edugate-eg.com/wp-content/uploads/2023/09/Logos-33-scaled.webp" alt="Partner Logo 1" />
                <img className="client-logo2" src="https://edugate-eg.com/wp-content/uploads/2023/09/Logos-32-scaled.webp" alt="Partner Logo 1" />
                <img className="client-logo2" src="https://edugate-eg.com/wp-content/uploads/2023/09/Logos-31-scaled.webp" alt="Partner Logo 1" />
                <img className="client-logo2" src="https://edugate-eg.com/wp-content/uploads/2023/09/Logos-30-scaled.webp" alt="Sponsor Logo 2" />
                <img className="client-logo2" src="https://edugate-eg.com/wp-content/uploads/2023/09/logos-25.webp" alt="Sponsor Logo 2" />
                <img className="client-logo2" src="https://edugate-eg.com/wp-content/uploads/2023/09/Logos-19.webp" alt="Sponsor Logo 2" />
                <img className="client-logo2" src="https://edugate-eg.com/wp-content/uploads/2023/09/Logos-18.webp" alt="Sponsor Logo 2" />
                <img className="client-logo2" src="https://edugate-eg.com/wp-content/uploads/2023/09/Logos-17.webp" alt="Sponsor Logo 2" />
                <img className="client-logo2" src="https://edugate-eg.com/wp-content/uploads/2023/09/Logos-16.webp" alt="Sponsor Logo 2" />
                <img className="client-logo2" src="https://edugate-eg.com/wp-content/uploads/2023/09/Logos-15.webp" alt="Sponsor Logo 2" />
                <img className="client-logo2" src="https://edugate-eg.com/wp-content/uploads/2023/09/Logos-13.webp" alt="Sponsor Logo 2" />
                <img className="client-logo2" src="https://edugate-eg.com/wp-content/uploads/2023/09/Logos-04.webp" alt="Sponsor Logo 2" />
                <img className="client-logo2" src="https://edugate-eg.com/wp-content/uploads/2023/09/Logos-03.webp" alt="Sponsor Logo 2" />
                <img className="client-logo2" src="https://edugate-eg.com/wp-content/uploads/2023/09/Logos-02.webp" alt="Sponsor Logo 2" />
               

                {/* Add more partner logos */}
              </div>
            </Carousel.Item>
            {/* Add more Carousel.Items for additional partner logo groups */}
          </Carousel>
        </div>
      </Container>
    </section>
  );
}

export default PartnerSection;
