import React from 'react';

import Header from './Header';

import Footer from './Footer'

import HelpButton from './HelpButton';
import NewRegistrationForm from './NewRegistrationForm';


const RegPage_stage = () => {
  const mapStyles = {
    width: '100%',
    height: '400px', // Adjust the height as needed
    border: '1px solid #ccc',
  };
// Update the src attribute of the iframe to include the "t=k" parameter for satellite view
const mapSrc = "https://edugate-eg.com/booking-form/";

  
  return (
    <div  className="home-container">
        <Header />
        <hr style={{ borderColor: 'darkred', marginTop: 0, borderWidth: '5px' }} />

        <div style={mapStyles}>
        <iframe
          title="stage registration "
          src={mapSrc} // Use the updated map source
          frameBorder="0"
          style={{ border: '0', width: '100%', height: '100%' }}
          allowFullScreen

        ></iframe>
      </div>
         <HelpButton/>


      <hr style={{ borderColor: 'darkred', marginTop: 0, borderWidth: '5px' }} />

      <Footer />



    </div>
  );
};

export default RegPage_stage;
