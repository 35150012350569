import React, { useState } from 'react';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import './HelpButton.css'; // Import the provided CSS

const HelpButton = () => {
  const [open, setOpen] = useState(false);

  const actions = [
    { icon: <WhatsAppIcon />, name: 'WhatsApp', action: () => openWhatsAppChat() },
    { icon: <EmailIcon />, name: 'Email', action: () => openEmail() },
    { icon: <PhoneIcon />, name: 'Phone', action: () => dialPhone() },
    { icon: <FacebookIcon />, name: 'Facebook', action: () => openSocialMedia('https://www.facebook.com/edutech.egy') },
    { icon: <InstagramIcon />, name: 'Instagram', action: () => openSocialMedia('https://www.instagram.com/edutech.eg/') },
    { icon: <LinkedInIcon />, name: 'LinkedIn', action: () => openSocialMedia('https://www.linkedin.com/company/edutech-fair-and-forum/') },
    { icon: <YouTubeIcon />, name: 'YouTube', action: () => openSocialMedia('https://www.youtube.com/@edutech1326') },

  ];

  const openWhatsAppChat = () => {
    window.open('https://wa.me/+201014004288');
  };

  const openEmail = () => {
    window.location.href = 'mailto:edutech@edugate-eg.com';
  };

  const dialPhone = () => {
    window.location.href = 'tel:+201014004288';
  };

  const openSocialMedia = (url) => {
    window.open(url, '_blank');
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#8B0000"
      },
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <div className="speed-dial-container">
        <SpeedDial
          ariaLabel="SpeedDial example"
          icon={<SpeedDialIcon style={{ color: 'white' }} />}
          open={open}
          onClose={() => setOpen(false)} // Close the SpeedDial
          onOpen={() => setOpen(true)} // Open the SpeedDial
          direction="up"
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={action.action}
            />
          ))}
        </SpeedDial>
      </div>
    </ThemeProvider>
  );
};

export default HelpButton;
