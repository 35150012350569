import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { createTheme, ThemeProvider } from '@mui/material/styles';
const apiKey = process.env.REACT_APP_API_KEY;

// Define CSS styles
const formContainerStyles = {
  padding: (theme) => theme.spacing(3),
  background: 'rgba(255, 255, 255, 0.8)',
  borderRadius: (theme) => theme.spacing(1),
  marginBottom: (theme) => theme.spacing(3), // Add margin to separate from the map
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#8B0000"
    },
  }
});

const formStyles = {
  display: 'flex',
  flexDirection: 'column',
  gap: (theme) => theme.spacing(2),
  padding: (theme) => theme.spacing(2), // Add padding for better styling
};

const buttonStyles = {
  marginTop: (theme) => theme.spacing(2),
};

// Function to convert Arabic numerals to English numerals
const convertArabicToEnglish = (input) => {
  const arabicNumbers = '٠١٢٣٤٥٦٧٨٩';
  const englishNumbers = '0123456789';
  let output = '';
  for (let i = 0; i < input.length; i++) {
    const index = arabicNumbers.indexOf(input[i]);
    output += index !== -1 ? englishNumbers[index] : input[i];
  }
  return output;
};

const RegistrationForm = () => {
  const [formData, setFormData] = useState({
    Corporate_Name:"",
    Contact_person:"",
    email:"",
    mobile:"",
    Country:"",
    city:"",
    Organization_website_or_Facebook_page: '',
    Kind_of_Participation: '',
    Kind_of_Participation_other:"",
    howDidYouKnow: '',
  });

  const [feedback, setFeedback] = useState({
    message: '',
    type: '', // 'success' or 'error'
  });

  const [language, setLanguage] = useState('en'); // Default language is English
  const [isSubmitting, setIsSubmitting] = useState(false);


  const handleChange = (event) => {
    const { name, value } = event.target;
  
    if (name === 'Kind_of_Participation') {
      setIsOtherSelected(value === 'Other');
    }

    const convertedValue = convertArabicToEnglish(value);
    setFormData({ ...formData, [name]: convertedValue });
  
   // setFormData({ ...formData, [name]: value });
  };
  

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

  
    // Check if any of the select fields have the default "Select" value
    if (
      formData.eduLevel === '' ||
      formData.Kind_of_Participation === '' ||
      formData.howDidYouKnow === '' ||
      formData.mobile === ''
    ) {
      setFeedback({
        message: 'Please select an option for all fields.',
        type: 'error',
      });
      return; // Prevent form submission if any field is still set to "Select"
    }

  
    try {
      // Send the form data to the backend API
      const response = await fetch('https://edutech-backend-471f7cb69116.herokuapp.com/api/enquire', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'api-key': apiKey, // Include the API key in the request headers
        },
        body: JSON.stringify(formData),
      });
      console.log(response.status)
  
      if (response.status === 201) {
  
        // Set the feedback for successful submission
        setFeedback({
          message: translations[language].successMessage,
          type: 'success',
        });

      } else if(response.status === 400){
        setIsSubmitting(false);
        setFeedback({
          message: translations[language].alreadyMessage,
          type: 'error',
        });

      }else {
        // Handle errors from the backend
        setFeedback({
          message: translations[language].errorMessage,
          type: 'error',
        });
      }
    } catch (error) {
      // Handle network errors
      setFeedback({
        message: translations[language].errorMessage,
        type: 'error',
      });
    }

  };
  

  const [isOtherSelected, setIsOtherSelected] = useState(false);

  const translations = {
    en: {
      Language:"عربي",
      other:"Specify Other",
      phoneMessage: "Phone number must be exactly 11 digits.",

      registrationForm: "Registration Form",
      firstNameLabel: "First Name",
      middleNameLabel: "Middle Name",
      lastNameLabel: "Last Name",
      emailLabel: "Email",
      mobileLabel: "Mobile",
      schoolNameLabel: "School Name",
      eduLevelLabel: "Educational Level",
      studyFieldLabel: "Intended Studying Field",
      howDidYouKnowLabel: "How Did You Know About Us",
      submitButtonLabel: "Submit",
      successMessage: "Thank you for choosing edutech! Your info was received successfully ",
      errorMessage: "An error occurred while processing the form.",
      alreadyMessage:"You have already registered with this Email",

      eduLevelOptions: [
        { value: 'Preparatory', label: 'Preparatory' },
        { value: 'Technical Secondary School', label: 'Technical Secondary School' },
        { value: 'Graduate', label: 'Graduate' },
      ],
      studyFieldOptions: [
        { value: 'Partner', label: 'Partner' },
        { value: 'Platinum  Sponsor', label: 'Platinum  Sponsor' },
        { value: 'Gold Sponsor', label: 'Gold Sponsor' },
        { value: 'Exhibitor', label: 'Exhibitor' },
        { value: 'Other', label: 'Other' },
      ],
      howDidYouKnowOptions: [
        { value: 'Google Search', label: 'Google Search' },
        { value: 'SMS', label: 'SMS' },
        { value: 'Email', label: 'Email' },
        { value: 'Radio', label: 'Radio' },    
        { value: 'Facebook', label: 'Facebook' },
        { value: 'Instagram', label: 'Instagram' },
        { value: 'Whatsapp', label: 'Whatsapp' },
        { value: 'Website', label: 'Website' },
        { value: 'Friends', label: 'Friends' },
        { value: 'Other', label: 'Other' },

      ],
    },
    
  };
  

  return (
    <div>
      
      <div
        style={{
          background: `url('https://edugate-eg.com/wp-content/uploads/2023/09/E03A6501-scaled.webp')`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          minHeight: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Container component="main" maxWidth="sm">
          <Paper elevation={3} sx={formContainerStyles}>

            <div style={{ color: "darkred", fontSize:"30px", textTransform:"uppercase" }}>
                Enquire Form
            </div>
            <ThemeProvider theme={theme}>
              <form onSubmit={handleSubmit} >
              <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
                  <TextField
                    label="Corporate Name"
                    name="Corporate_Name"
                    value={formData.Corporate_Name}
                    onChange={handleChange}
                    required
                    sx={{ flex: 1 }}
                  />
                   <TextField
                    label="Contact Person"
                    name="Contact_person"
                    value={formData.Contact_person}
                    onChange={handleChange}
                    required
                    sx={{ flex: 1 }}
                  />
                </Box>
                <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
                <TextField
        label={translations[language].mobileLabel}
        name="mobile"
        type="tel"
        value={formData.mobile}
        onChange={handleChange}
        required
        sx={{ flex: 1 }}
        inputProps={{ pattern: '^\\+?\\d{1,3}?[-.\\s]?\\(?\\d{3}\\)?[-.\\s]?\\d{3}[-.\\s]?\\d{4}$' }}
      />
                  <TextField
                    label={translations[language].emailLabel}
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    sx={{ flex: 1 }}
                  />
                </Box>
                <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
                
                  <TextField
                    label="Country"
                    name="Country"
                    value={formData.Country}
                    onChange={handleChange}
                    required
                    sx={{ flex: 1 }}
                  />
                  <TextField
                    label="City"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    required
                    sx={{ flex: 1 }}
                  />
                </Box>
                <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
                
                  <TextField
                    label="Organization website or Facebook page"
                    name="Organization_website_or_Facebook_page"
                    value={formData.Organization_website_or_Facebook_page}
                    onChange={handleChange}
                    required
                    sx={{ flex: 1 }}
                  />
                </Box>
     

  <FormControl fullWidth sx={{ marginBottom: '20px' }}>
  <InputLabel id="Kind_of_Participation-label">
  Kind of Participation
  </InputLabel>
  <Select
    labelId="Kind_of_Participation-label"
    id="Kind_of_Participation"
    name="Kind_of_Participation"
    value={formData.Kind_of_Participation}
    onChange={handleChange}
    required
    label="Kind of Participation"
  >
    {translations[language].studyFieldOptions.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ))}
  </Select>
  {isOtherSelected && (
    <TextField
      label= "Specify"
      name="Kind_of_Participation_other"
      value={formData.Kind_of_Participation_other}
      onChange={handleChange}
      sx={{ m: 1 }}
      required
    />
  )}
</FormControl>


<FormControl fullWidth sx={{ marginBottom: '20px' }}>
    <InputLabel id="howDidYouKnow-label">
    How did you hear about us?
    </InputLabel>
    <Select
      labelId="howDidYouKnow-label"
      id="howDidYouKnow"
      name="howDidYouKnow"
      value={formData.howDidYouKnow}
      onChange={handleChange}
      required
      label={translations[language].howDidYouKnowLabel}
    >
      {translations[language].howDidYouKnowOptions.map((option) => ( // Updated this line
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={buttonStyles}
                  disabled={isSubmitting}
                >
                  {translations[language].submitButtonLabel}
                </Button>
                {feedback.message && (
                  <Typography
                    variant="body1"
                    style={{ color: feedback.type === 'error' ? 'red' : 'green' }}
                  >
                    {feedback.message}
                  </Typography>
                )}
              </form>
            </ThemeProvider>
          </Paper>
        </Container>
      </div>
    </div>
  );
};

export default RegistrationForm;
