import React, { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';


const SubscribeToNewsletter = () => {
  const [showSubscribe, setShowSubscribe] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [feedback, setFeedback] = useState({
    message: '',
    type: '', // 'success' or 'error'
  });

  const theme = createTheme({
    palette: {
      primary: {
        main: "#8B0000"
      },
    }
  });

  const [formData, setFormData] = useState({
    email:"",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
  

    setFormData({ ...formData, [name]: value });  };

  useEffect(() => {
    // Disable scrolling when the modal is open
    if (showSubscribe) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    // Cleanup function to re-enable scrolling when the component unmounts
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [showSubscribe]);

  const apiKey = process.env.REACT_APP_API_KEY;


  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
  
    if (!formData.email) {
      setFeedback({
        message: "Please enter your email",
        type: 'error',
      });
      setIsSubmitting(false);
      return;
    }
  
    try {
      // Send the form data to the backend API
      const response = await fetch('https://edutech-backend-471f7cb69116.herokuapp.com/api/newsletter', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'api-key': apiKey, // Include the API key in the request headers
        },
        body: JSON.stringify(formData),
      });
  
      if (response.status === 201) {
        setFeedback({
          message: "Subscribed successfully",
          type: 'success',
        });
        setTimeout(() => {
          setShowSubscribe(false);
        }, 3000);
      } else {
        // Handle errors from the backend
        setFeedback({
          message: "Error submitting the form",
          type: 'error',
        });
      }
    } catch (error) {
      // Handle network errors
      setFeedback({
        message: "Error submitting the form",
        type: 'error',
      });
    }
  };
  

  const handleClose = () => {
    setShowSubscribe(false);
  };

  return (
    <>
      {showSubscribe && (
        <div
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black overlay
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1000', // Ensure it appears above other content
            

          }}
        >
          <div
            style={{
              backgroundColor: '#fff',
              padding: '20px',
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
              borderRadius: '8px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              position: 'relative', // Required for absolute positioning of button
              backgroundImage: `url("https://edugate-eg.com/wp-content/uploads/2024/03/171166430740415368-1.webp")`, // Replace with your image URL
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              minHeight:"250px",

            }}
          >
                    <Container component="main" sx={{ width: '90%' }}>

            <CloseIcon
              style={{ cursor: 'pointer', position: 'absolute', top: '10px', right: '10px' ,color:"white", marginBottom:"10px"}}
              onClick={handleClose}
            />
            <h3 style={{ fontWeight: "bold", marginBottom: '20px',color:"white",marginTop:"20px" }}>
              Subscribe to our newsletter to get the latest news!
            </h3>
            <div
              style={{
                width: '100%',
                marginBottom: '20px',
              }}
            >
              <ThemeProvider theme={theme}>
              <form onSubmit={handleSubmit} >
                

              <Box sx={{ display: 'flex', gap: '20px' }}>

                <TextField
                  label="Enter your email"
                  variant="outlined"
                  fullWidth
                  required
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  sx={{ flex: 1 }}
                  style={{ alignItems:"center",marginBottom: '10px', backgroundColor: 'rgba(255, 255, 255, 0.8)', width: '80%', textAlign: 'center' }} // Set background color with opacity, smaller width, and center align
                />
                </Box>
                <Button
  variant="contained"
  color="primary"
  style={{ width: '80%', alignItems: "center" }} // Set width and background color for the button
  disabled={isSubmitting}
  type="submit"
>
  Subscribe
</Button>

                </form>

                {feedback.message && (
                  <Typography
                    variant="body1"
                    style={{ color: feedback.type === 'error' ? 'red' : 'green' }}
                  >
                    {feedback.message}
                  </Typography>
                )}
              </ThemeProvider>

            </div>
            </Container>

          </div>
        </div>
      )}
    </>
  );
};

export default SubscribeToNewsletter;
