import React from 'react';
import './FAQItem.css';
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa';

const FAQItem = ({ question, answer }) => {
  const [isActive, setIsActive] = React.useState(false);

  const handleToggle = () => {
    setIsActive(!isActive);
  };

  return (
    <div className={`faq-item ${isActive ? 'active' : ''}`}>
      <div className="faq-question" onClick={handleToggle}>
        <span className="faq-text">{question}</span>
        <span className="faq-toggle">
          {isActive ? <FaMinusCircle className="icon" /> : <FaPlusCircle className="icon" />}
        </span>
      </div>
      <div className={`faq-answer ${isActive ? 'active' : ''}`}>{answer}</div>
    </div>
  );
};

export default FAQItem;
