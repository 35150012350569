import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { createTheme, ThemeProvider } from '@mui/material/styles';
const apiKey = process.env.REACT_APP_API_KEY;

// Define CSS styles
const formContainerStyles = {
  padding: (theme) => theme.spacing(3),
  background: 'rgba(255, 255, 255, 0.8)',
  borderRadius: (theme) => theme.spacing(1),
  marginBottom: (theme) => theme.spacing(3), // Add margin to separate from the map
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#8B0000"
    },
  }
});

const formStyles = {
  display: 'flex',
  flexDirection: 'column',
  gap: (theme) => theme.spacing(2),
  padding: (theme) => theme.spacing(2), // Add padding for better styling
};

const buttonStyles = {
  marginTop: (theme) => theme.spacing(2),
};

const RegistrationForm = () => {
  const [formData, setFormData] = useState({
    // ...other form fields
    firstName:"",
    middleName:"",
    lastName:"",
    email:"",
    mobile:"",
    schoolName:"",
    eduLevel: '',
    studyField: '',
    studyFieldOther:"",
    howDidYouKnow: '',
    attend:"yes"
  });

  const [feedback, setFeedback] = useState({
    message: '',
    type: '', // 'success' or 'error'
  });

  const [language, setLanguage] = useState('en'); // Default language is English
  const [isSubmitting, setIsSubmitting] = useState(false);

  const toggleLanguage = () => {
    // Toggle between English and Arabic
    setLanguage(language === 'en' ? 'ar' : 'en');
  };

  const convertArabicToEnglish = (input) => {
    const arabicNumbers = '٠١٢٣٤٥٦٧٨٩';
    const englishNumbers = '0123456789';
    let output = '';
    for (let i = 0; i < input.length; i++) {
      const index = arabicNumbers.indexOf(input[i]);
      output += index !== -1 ? englishNumbers[index] : input[i];
    }
    return output;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
  
    if (name === 'studyField') {
      setIsOtherSelected(value === 'Other');
    }
  
    const convertedValue = convertArabicToEnglish(value);
    setFormData({ ...formData, [name]: convertedValue });  };
  

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    // Check if any of the select fields have the default "Select" value
    if (
      formData.eduLevel === '' ||
      formData.studyField === '' ||
      formData.howDidYouKnow === '' ||
      formData.mobile === ''
    ) {
      setFeedback({
        message: 'Please select an option for all fields.',
        type: 'error',
      });
      return; // Prevent form submission if any field is still set to "Select"
    }
    
  
    try {
      // Send the form data to the backend API
      const response = await fetch('https://edutech-backend-471f7cb69116.herokuapp.com/api/registrationsEvent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'api-key': apiKey, // Include the API key in the request headers

        },
        body: JSON.stringify(formData),
      });
  
      if (response.status === 201) {

        setFeedback({
          message: translations[language].successMessage,
          type: 'success',
        });
  

      } else if(response.status === 400){
        setIsSubmitting(false);
        setFeedback({
          message: translations[language].alreadyMessage,
          type: 'error',
        });

      }else {
        // Handle errors from the backend
        setFeedback({
          message: translations[language].errorMessage,
          type: 'error',
        });
      }
    } catch (error) {
      // Handle network errors
      setFeedback({
        message: translations[language].errorMessage,
        type: 'error',
      });
    }

 
  };

  
  const [isOtherSelected, setIsOtherSelected] = useState(false);

  const translations = {
    en: {
      Language:"عربي",
      other:"Specify Other",
      phoneMessage: "Phone number must be exactly 11 digits.",

      registrationForm: "Registration Form",
      firstNameLabel: "First Name",
      middleNameLabel: "Middle Name",
      lastNameLabel: "Last Name",
      emailLabel: "Email",
      mobileLabel: "Mobile",
      schoolNameLabel: "School Name",
      eduLevelLabel: "Educational Level",
      studyFieldLabel: "Intended Studying Field",
      howDidYouKnowLabel: "How Did You Know About Us",
      submitButtonLabel: "Submit",
      successMessage: "Thank you for registering!",
      errorMessage: "An error occurred while processing the form.",
      alreadyMessage:"You have already registered with this Email",
      eduLevelOptions: [
        { value: 'Preparatory', label: 'Preparatory' },
        { value: 'Technical Secondary School', label: 'Technical Secondary School' },
        { value: 'Graduate', label: 'Graduate' },
      ],
      studyFieldOptions: [
        { value: 'Cars', label: 'Cars' },
        { value: 'New and Renewable Technology', label: 'New and Renewable Technology' },
        { value: 'Gold and Jewelry', label: 'Gold and Jewelry' },
        { value: 'Communication and Information Technology', label: 'Communication and Information Technology' },
        { value: 'Shipping and Logistics', label: 'Shipping and Logistics' },
        { value: 'Construction', label: 'Construction' },
        { value: 'Retail Trade', label: 'Retail Trade' },
        { value: 'Agriculture and Land Reclamation', label: 'Agriculture and Land Reclamation' },
        { value: 'Other', label: 'Other' },
      ],
      howDidYouKnowOptions: [
        { value: 'Google Search', label: 'Google Search' },
        { value: 'SMS', label: 'SMS' },
        { value: 'School', label: 'School' },
        { value: 'Email', label: 'Email' },
        { value: 'Radio', label: 'Radio' },    
        { value: 'Facebook', label: 'Facebook' },
        { value: 'Instagram', label: 'Instagram' },
        { value: 'Whatsapp', label: 'Whatsapp' },
        { value: 'Website', label: 'Website' },
        { value: 'Friends', label: 'Friends' },
      ],
    },
    ar: {
      Language:"English",
      other:"اذكر الاخري",
      phoneMessage: "يجب أن يتكون رقم الهاتف من 11 رقمًا بالضبط",

      registrationForm: "التسجيل",
      firstNameLabel: "الاسم الأول",
      middleNameLabel: "الاسم الأوسط",
      lastNameLabel: "الاسم الأخير",
      emailLabel: "البريد الإلكتروني",
      mobileLabel: "الجوال",
      schoolNameLabel: "اسم المدرسة",
      eduLevelLabel: " المرحلة الدراسية",
      studyFieldLabel: "  المجال المهتم بدراسته",
      howDidYouKnowLabel: "كيف عرفت عنا",
      submitButtonLabel: "إرسال",
      successMessage: "شكرًا لتسجيلك!",
      errorMessage: "حدث خطأ أثناء معالجة النموذج.",
      alreadyMessage:"لقد قمت بالتسجيل بهذا البريد الالكتروني مسبقا",

      eduLevelOptions: [
        { value: 'Preparatory', label: 'اعدادي' },
        { value: 'Technical Secondary School', label: 'ثانوي فني' },
        { value: 'Graduate', label: 'خريج' },
      ],
      studyFieldOptions: [
        { value: 'Cars', label: 'السيارات' },
        { value: 'New and Renewable Technology', label: 'الطاقة الجديدة والمتجددة' },
        { value: 'Gold and Jewelry', label: 'الذهب المشغولات' },
        { value: 'Communication and Information Technology', label: '  الاتصالات و تكنولوجيا المعلومات' },
        { value: 'Shipping and Logistics', label: ' النقل البحري و اللوجستيات' },
        { value: 'Construction', label: 'التشييد والبناء' },
        { value: 'Retail Trade', label: 'تجارة التجزئة' },
        { value: 'Agriculture and Land Reclamation', label: '   الزراعة و استصلاح الاراضي' },
        { value: 'Other', label: 'اخري' },
      ],
      howDidYouKnowOptions: [
        { value: 'Google Search', label: 'البحث في جوجل' },
        { value: 'SMS', label: 'الرسائل النصية' },
        { value: 'School', label: 'المدرسة' },
        { value: 'Email', label: 'البريد الإلكتروني' },
        { value: 'Radio', label: 'الراديو' },    
        { value: 'Facebook', label: 'فيسبوك' },
        { value: 'Instagram', label: 'إنستجرام' },
        { value: 'Whatsapp', label: 'واتساب' },
        { value: 'Website', label: 'الموقع الإلكتروني' },
        { value: 'Friends', label: 'الأصدقاء' },
      ],
    },
  };
  
  

  // Define options for select fields
  const eduLevelOptions = [
    { value: 'Preparatory', label: 'Preparatory' },
    { value: 'Technical Secondary School', label: 'Technical Secondary School' },
    { value: 'Graduate', label: 'Graduate' },
  ];

  // Add your study field options here
  const studyFieldOptions = [
    { value: 'Cars', label: 'Cars' },
    { value: 'New and Renewable Technology', label: 'New and Renewable Technology' },
    { value: 'Gold and Jewelry', label: 'Gold and Jewelry' },
    { value: 'Communication and Information Technology', label: 'Communication and Information Technology' },
    { value: 'Shipping and Logistics', label: 'Shipping and Logistics' },
    { value: 'Construction', label: 'Construction' },
    { value: 'Retail Trade', label: 'Retail Trade' },
    { value: 'Agriculture and Land Reclamation', label: 'Agriculture and Land Reclamation' },
    { value: 'Other', label: 'Other' },
  ];

  // Add your how did you know options here
  const howDidYouKnowOptions = [
    { value: 'Google Search', label: 'Google Search' },
    { value: 'SMS', label: 'SMS' },
    { value: 'School', label: 'School' },
    { value: 'Email', label: 'Email' },
    { value: 'Radio', label: 'Radio' },    
    { value: 'Facebook', label: 'Facebook' },
    { value: 'Instagram', label: 'Instagram' },
    { value: 'Whatsapp', label: 'Whatsapp' },
    { value: 'Website', label: 'Website' },
    { value: 'Friends', label: 'Friends' },
  ];

  return (
    <div>
      
      <div
        style={{
          background: `url('https://edugate-eg.com/wp-content/uploads/2023/09/1024-1-scaled.webp')`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          minHeight: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Container component="main" sx={{ width: '90%' }}>
          <Paper elevation={3} sx={formContainerStyles}>

          <div
  style={{
    display: 'flex',
    justifyContent: 'flex-end', // Position on the right side
    alignItems: 'center',
    marginTop: '20px', // Add some top margin for spacing
  }}
>
<ThemeProvider theme={theme}>

  <Button
    onClick={toggleLanguage}
    style={{
      fontSize: '18px', // Adjust the font size to make it bigger
      padding: '10px 20px', // Adjust padding to increase button size
    }}
  >
    {translations[language].Language}
  </Button>
  </ThemeProvider>

</div>
            <div style={{ color: "darkred", fontSize:"30px", textTransform:"uppercase" }}>
              {translations[language].registrationForm} {/* Use translations */}
            </div>
            <ThemeProvider theme={theme}>
              <form onSubmit={handleSubmit} >
              <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
                  <TextField
                    label={translations[language].firstNameLabel}
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                    sx={{ flex: 1 }}
                  />
                   <TextField
                    label={translations[language].lastNameLabel}
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                    sx={{ flex: 1 }}
                  />
                </Box>
                <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
                <TextField
        label={translations[language].mobileLabel}
        name="mobile"
        type="tel"
        value={formData.mobile}
        onChange={handleChange}
        required
        sx={{ flex: 1 }}
        inputProps={{ pattern: '^\\+?\\d{1,3}?[-.\\s]?\\(?\\d{3}\\)?[-.\\s]?\\d{3}[-.\\s]?\\d{4}$' }}
      />
                  <TextField
                    label={translations[language].emailLabel}
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    sx={{ flex: 1 }}
                  />
                </Box>
                <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
                
                  <TextField
                    label={translations[language].schoolNameLabel}
                    name="schoolName"
                    value={formData.schoolName}
                    onChange={handleChange}
                    required
                    sx={{ flex: 1 }}
                  />
                </Box>
     <FormControl fullWidth sx={{ marginBottom: '20px' }}>
    <InputLabel id="eduLevel-label">
      {translations[language].eduLevelLabel}
    </InputLabel>
    <Select
      labelId="eduLevel-label"
      id="eduLevel"
      name="eduLevel"
      value={formData.eduLevel}
      onChange={handleChange}
      required
      label={translations[language].eduLevelLabel}
    >
      {translations[language].eduLevelOptions.map((option) => ( // Updated this line
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>

  <FormControl fullWidth sx={{ marginBottom: '20px' }}>
  <InputLabel id="studyField-label">
    {translations[language].studyFieldLabel}
  </InputLabel>
  <Select
    labelId="studyField-label"
    id="studyField"
    name="studyField"
    value={formData.studyField}
    onChange={handleChange}
    required
    label={translations[language].studyFieldLabel}
  >
    {translations[language].studyFieldOptions.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ))}
  </Select>
  {isOtherSelected && (
    <TextField
      label= {translations[language].other}
      name="studyFieldOther"
      value={formData.studyFieldOther}
      onChange={handleChange}
      sx={{ m: 1 }}
      required
    />
  )}
</FormControl>


<FormControl fullWidth sx={{ marginBottom: '20px' }}>
    <InputLabel id="howDidYouKnow-label">
      {translations[language].howDidYouKnowLabel}
    </InputLabel>
    <Select
      labelId="howDidYouKnow-label"
      id="howDidYouKnow"
      name="howDidYouKnow"
      value={formData.howDidYouKnow}
      onChange={handleChange}
      required
      label={translations[language].howDidYouKnowLabel}
    >
      {translations[language].howDidYouKnowOptions.map((option) => ( // Updated this line
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={buttonStyles}
                  disabled={isSubmitting}

                >
                  {translations[language].submitButtonLabel}
                </Button>
                {feedback.message && (
                  <Typography
                    variant="body1"
                    style={{ color: feedback.type === 'error' ? 'red' : 'green' }}
                  >
                    {feedback.message}
                  </Typography>
                )}
              </form>
            </ThemeProvider>
          </Paper>
        </Container>
      </div>
    </div>
  );
};

export default RegistrationForm;
