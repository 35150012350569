import React, { useState, useEffect } from 'react';
import CarouselComponent from './CarouselComponent';
import CountdownComponent from './CountdownComponent';
import Header from './Header';
import ServicesComponent from './ServicesComponent';
import ClientCarouselSection from './ClientCarouselSection';
import ContactSectionComponent from './ContactSectionComponent';
import Footer from './Footer';
import FAQ from './FAQ';
import FigureCard from './FigureCard';
import HelpButton from './HelpButton';
import BlogSection from './BlogSection';
import PartnerSection from './PartnerSection';
import SubscribeToNewsletter from './SubscribeToNewsletter';
import axios from 'axios';
import { useMediaQuery } from '@mui/material';
import EventAnnouncement from './EventAnnouncement';

const Home = () => {
  const [figuresData, setFiguresData] = useState({
    students: 0,
    events: 0,
    exhibitors: 0,
    international_organizations: 0,
    ministries: 0,
  });
  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiKey = process.env.REACT_APP_API_KEY;
        const response = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/figures', {
          headers: {
            'Content-Type': 'application/json',
            'api-key': apiKey,
          },
        });
        setFiguresData(response.data[0]);
      } catch (error) {
        console.error('Error fetching figures data:', error);
      }
    };
  
    fetchData();
  }, []);

  return (
    <main>
      <Header />
      <CarouselComponent />

      <SubscribeToNewsletter />

      <hr style={{ borderColor: '#d32f2f', margin: '20px 0', borderWidth: '2px', borderStyle: 'solid' }} />

      <ServicesComponent />

      <hr style={{ borderColor: '#d32f2f', margin: '20px 0', borderWidth: '2px', borderStyle: 'solid' }} />

      <CountdownComponent />

      <section style={{ padding: '40px 0', backgroundColor: '#f9f9f9' }}>
        <div style={{ textAlign: 'center', marginBottom: '30px' }}>
          <h2 style={{ letterSpacing:"1.5px", fontSize:"2.5rem", fontWeight:"bold", marginBottom: '25px', textTransform: 'uppercase', color: '#d32f2f' }}>
            The Numbers That Matter
          </h2>
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))', gap: '20px', justifyContent: 'center' }}>
          <FigureCard label="Visitors" number={figuresData.students} />
          <FigureCard label="Events" number={figuresData.events} />
          <FigureCard label="Exhibitors" number={figuresData.exhibitors} />
          <FigureCard label="Countries" number={figuresData.international_organizations} />
          <FigureCard label="Ministries" number={figuresData.ministries} />
        </div>
      </section>

      <hr style={{ borderColor: '#d32f2f', margin: '20px 0', borderWidth: '2px', borderStyle: 'solid' }} />

      <ClientCarouselSection />
      <PartnerSection />

      <hr style={{ borderColor: '#d32f2f', margin: '20px 0', borderWidth: '2px', borderStyle: 'solid' }} />

      <BlogSection />

      <hr style={{ borderColor: '#d32f2f', margin: '20px 0', borderWidth: '2px', borderStyle: 'solid' }} />

      <ContactSectionComponent />
      
      <hr style={{ borderColor: '#d32f2f', margin: '20px 0', borderWidth: '2px', borderStyle: 'solid' }} />

      <FAQ />
      <HelpButton />

      <hr style={{ borderColor: '#d32f2f', margin: '20px 0', borderWidth: '2px', borderStyle: 'solid' }} />

      <Footer />
    </main>
  );
};

export default Home;
//////test