import React from 'react';

import Header from './Header';

import VideoImageSection from './VideoImageSection'
import Footer from './Footer'
import HelpButton from './HelpButton';



const EventPage = () => {
  return (
    <div className="home-container">
        <Header />
        <VideoImageSection />
        <HelpButton/>




      <hr style={{ borderColor: 'darkred', marginTop: 0, borderWidth: '5px' }} />

      <Footer />



    </div>
  );
};

export default EventPage;
