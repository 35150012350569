import React, { useState, useEffect } from 'react';
import './Footer.css';
import axios from 'axios';
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaYoutube,
  FaEnvelope,
  FaPhone,
  FaHome,
} from 'react-icons/fa';
import LocationOnIcon from '@mui/icons-material/LocationOn';
function Footer() {
  const [contact, setContact] = useState({});

  useEffect(() => {
    const fetchContactData = async () => {
      try {
        const apiKey = process.env.REACT_APP_API_KEY;
        const response = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/contact', {
          headers: {
            'Content-Type': 'application/json',
            'api-key': apiKey,
          },
        });
        setContact(response.data[0]);
      } catch (error) {
        console.error('Error fetching contact data:', error);
      }
    };

    fetchContactData();
  }, []);

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content">
          <div className="footer-links">
            <h4>Quick Links</h4>
            <ul>
              <li><a href="/">Home</a></li>
              <li><a href="/#info-section">About</a></li>
              <li><a href="/regpage">Register</a></li>
              <li><a href="/EventPage">Fair</a></li>
              <li><a href="/AgendaPage">Agenda</a></li>
              <li><a href="/SpeakerPage">Speakers</a></li>
              <li><a href="/SessionsPage">Sessions</a></li>
              <li><a href="/#contact-section">Contact Us</a></li>
            </ul>
          </div>

          <div className="footer-contact">
            <h4>Contact Us</h4>
            <p>
              <FaEnvelope className="icon" />{' '}
              <a href={`mailto:${contact.email}`}>{contact.email}</a>
            </p>
            <p>
              <FaPhone className="icon" />{' '}
              <a href={`tel:${contact.phone}`}>{contact.phone}</a>
            </p>
            <p>
              <LocationOnIcon className="icon" />{' '}
              <a href={contact.link} target="_blank" rel="noopener noreferrer">{contact.address}</a>
            </p>
          </div>

          <div className="footer-logo">
            <a href="/">
              <img src="./footer.png" alt="Your Logo" className="responsive-logo" />
            </a>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="footer-social">
            {contact.facebook && <a href={contact.facebook} target="_blank" rel="noopener noreferrer" aria-label="Facebook"><FaFacebook /></a>}
            {contact.instagram && <a href={contact.instagram} target="_blank" rel="noopener noreferrer" aria-label="Instagram"><FaInstagram /></a>}
            {contact.linkedin && <a href={contact.linkedin} target="_blank" rel="noopener noreferrer" aria-label="LinkedIn"><FaLinkedin /></a>}
            {contact.youtube && <a href={contact.youtube} target="_blank" rel="noopener noreferrer" aria-label="YouTube"><FaYoutube /></a>}
          </div>
          <div className="footer-text">
            <p>&copy; {new Date().getFullYear()} EDUTECH. All Rights Reserved.</p>
            <p>Powered by EDUGATE</p>
            <a href='https://www.linkedin.com/in/youssef-thabet-79754021b' target="_blank" rel="noopener noreferrer" aria-label="Youssef Thabet">
              Designed by Eng. Youssef Thabet
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
