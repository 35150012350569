import React, { useState, useEffect } from 'react';//
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ReactSVG } from 'react-svg';
import './RegistrationForm.css';
import PackagesList from './PackagesList';


import Select from 'react-select';

const RegistrationForm = () => {
  const [formData, setFormData] = useState({
    email: '',
    name: '',
    companyName: '',
    title: '',
    phoneNumber: '',
    boothNumber: '',
    sponsorType: 'Partner', // Default sponsor type
  });

  const [formErrors, setFormErrors] = useState({
    email: '',
    name: '',
    companyName: '',
    title: '',
    phoneNumber: '',
    boothNumber: '',
  });

  const [sponsorOptions, setSponsorOptions] = useState([]); // Initialize state to store sponsor options
  const [packages, setSpackages] = useState([]); // Initialize state to store sponsor options


  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiKey = process.env.REACT_APP_API_KEY; // Load API key from environment variable
        const response = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/packages', {
          headers: {
            'Content-Type': 'application/json',
            'api-key': apiKey, // Include the API key in the request headers
          },
        });
        const simplifiedOptions = response.data.map(option => ({
          value: option.name,
          label: option.label,
        }));
        setSponsorOptions(simplifiedOptions); // Store simplified sponsor options in state
      } catch (error) {
        console.error('Error fetching sponsor options:', error);
      }
    };
  
    fetchData();
  }, []);
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiKey = process.env.REACT_APP_API_KEY; // Load API key from environment variable
        const response = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/packages', {
          headers: {
            'Content-Type': 'application/json',
            'api-key': apiKey, // Include the API key in the request headers
          },
        });
        setSpackages(response.data); // Set the packages data in state
      } catch (error) {
        console.error('Error fetching packages data:', error);
      }
    };
  
    fetchData();
  }, []);
  
  
  

  const [submissionStatus, setSubmissionStatus] = useState(null); // null: not submitted, true: success, false: error


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateEmail = (email) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  const validateForm = () => {
    const newFormErrors = {};

    // Validation logic...
    if (!validateEmail(formData.email)) {
      newFormErrors.email = 'Invalid email format';
    }

    setFormErrors(newFormErrors);
    return Object.keys(newFormErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmissionStatus(null);
  
    // Check if all required fields are filled
    if (!formData.name || !formData.companyName || !formData.title || !formData.phoneNumber || !formData.email || !formData.boothNumber) {
      setFormErrors({
        ...formErrors,
        name: !formData.name ? 'Name is required' : '',
        companyName: !formData.companyName ? 'Company Name is required' : '',
        title: !formData.title ? 'Position in Company is required' : '',
        phoneNumber: !formData.phoneNumber ? 'Phone Number is required' : '',
        email: !formData.email ? 'Email is required' : '',
        boothNumber: !formData.boothNumber ? 'Booth Number is required' : '',
      });
      return;
    }
    
  
    if (!validateForm()) {
      return;
    }
  
    try {
      const response = await axios.post('https://edutech-backend-471f7cb69116.herokuapp.com/api/sponsors', formData);
      console.log('Data saved:', response.data);
      
      // Update the selected booth status to booked
      const boothUpdateResponse = await axios.put(`https://edutech-backend-471f7cb69116.herokuapp.com/api/booths/${formData.boothNumber}`, { isBooked: true });
      console.log('Booth update response:', boothUpdateResponse.data);
  
      setSubmissionStatus(true);
    } catch (error) {
      console.error('Error saving data:', error);
      setSubmissionStatus(false);
    }
  };
  

  const handleBoothClick = (event) => {
    event.preventDefault();
    const selectedBooth = event.target.getAttribute('data-booth');
    if (selectedBooth) {
      setFormData((prevData) => ({
        ...prevData,
        boothNumber: selectedBooth,
      }));
    }
  };

  const svgStyles = {
    width: '85%',
    height: 'auto',
    marginTop: '20px',
  };

  const containerStyles = {
    display: 'flex',
    justifyContent: 'space-between',
  };

  const formContainerStyles = {
    flex: 1,
    marginRight: '20px',
  };
  const buttonStyles = {
    marginTop: '10px',
    padding: '10px 20px',
    background: 'darkred', // Set button color to dark red
    color: '#fff',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
  };
  const errorStyles = {
    color: 'darkred', // Set error message color to dark red
    fontSize: '12px',
    marginTop: '5px',
  };
  const inputStyles = {
    margin: '5px 0',
    padding: '8px',
    border: '1px solid #ddd',
    borderRadius: '3px',
    width: '100%',
  };
  const formStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
    maxWidth: '400px',
    padding: '20px',
    border: '1px solid #ddd',
    borderRadius: '5px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  };
  const selectStyles = {
    ...inputStyles,
  };
  const [boothData, setBoothData] = useState([]); // Fetch booth data from your database

  useEffect(() => {
    // Fetch booth data from your API
    axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/booths')
      .then(response => {
        setBoothData(response.data);
      })
      .catch(error => {
        console.error('Error fetching booth data:', error);
      });
  }, []);


  const CustomSelect = ({ options, onChange, value }) => {
    return (
      <Select
        options={options}
        value={value}
        onChange={onChange}
        components={{ Option: CustomOption }}
      />
    );
  };
  

  const CustomOption = ({ innerProps, label, data }) => {
    return (
      <div
        {...innerProps}
        style={{
          padding: '8px',
          backgroundColor: data.isFocused ? 'darkred' : 'white',
          color: data.isFocused ? 'white' : 'black',
          cursor: 'pointer',
        }}
      >
        {label}
      </div>
    );
  };
  const handleOptionHover = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      sponsorType: value,
    }));
  };
  

  //const sponsorOptions = [
    //{ value: 'exhibitor', label: 'Exhibitor' },
    //{ value: 'partner', label: 'Partner' },
    //{ value: 'gold', label: 'Gold Sponsor' },
    //{ value: 'platinum', label: 'Platinum Sponsor' },
  //];


  return (
    <div className="form-container">
    <div className="container" style={containerStyles}>
      <div style={formContainerStyles}>
        <div style={formStyles}>
          <h2>Register as Sponsor</h2>
          
          <form onSubmit={handleSubmit}>

            <input
          style={inputStyles}
          type="text"
          name="name"
          placeholder="Name"
          value={formData.name}
          onChange={handleChange}
        />
        {formErrors.name && <div  style={errorStyles} className="error">{formErrors.name}</div>}

        <input
          style={inputStyles}
          type="text"
          name="companyName"
          placeholder="Company Name"
          value={formData.companyName}
          onChange={handleChange}
        />
        {formErrors.companyName && <div style={errorStyles} className="error">{formErrors.companyName}</div>}

        <input
          style={inputStyles}
          type="text"
          name="title"
          placeholder="Position in Company"
          value={formData.title}
          onChange={handleChange}
        />
        {formErrors.title && <div style={errorStyles} className="error">{formErrors.title}</div>}

        <input
          style={inputStyles}
          type="text"
          name="phoneNumber"
          placeholder="Phone Number"
          value={formData.phoneNumber}
          onChange={handleChange}
        />
        {formErrors.phoneNumber && <div  style={errorStyles} className="error">{formErrors.phoneNumber}</div>}

        <input
          style={inputStyles}
          type="text"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
        />
        {formErrors.email && <div style={errorStyles} className="error">{formErrors.email}</div>}

        {formErrors.boothNumber && <div style={errorStyles} className="error">{formErrors.boothNumber}</div>}

        <CustomSelect
              options={sponsorOptions}
              value={sponsorOptions.find(option => option.value === formData.sponsorType)}
              onChange={selectedOption =>
                handleChange({ target: { name: 'sponsorType', value: selectedOption.value } })
              }
            />
        <input
        style={inputStyles}
        type="text"
        name="boothNumber"
        placeholder="Select Booth Number from the Map"
        value={formData.boothNumber}
        readOnly // Make the input field read-only
      />
      {formErrors.boothNumber && <div style={errorStyles} className="error">{formErrors.boothNumber}</div>}
            <button style={buttonStyles} type="submit">
              Register
            </button>
          </form>
          {submissionStatus !== null && (
          <div>
            {submissionStatus ? (
              <p style={{ color: 'green' }}>
              You gave registered successfully! 
             Please check your email inbox or spam. 
                 We will reach you out soon</p>
            ) : (
              <p style={{ color: 'darkred' }}>Error submitting form. Please try again.</p>
            )}
          </div>
        )}
      </div>

    </div>
    <div style={{ flex: 1 }}>
    <ReactSVG
  src="/edutech_floor_plan.svg"
  style={svgStyles}
  onClick={(event) => {
    const selectedBooth = event.target.getAttribute('data-booth');
    const selectedBoothData = boothData.find(booth => booth.boothNumber === selectedBooth);

    if (selectedBoothData && !selectedBoothData.isBooked) {
      handleBoothClick(event);
    }
  }}
  evalScripts="always"
  beforeInjection={(svg) => {
    const boothElements = svg.querySelectorAll('.booth');

    boothElements.forEach((boothElement) => {
      const boothNumber = boothElement.getAttribute('data-booth');
      const booth = boothData.find((booth) => booth.boothNumber === boothNumber);

      if (booth) {
        if (booth.isBooked) {
          boothElement.setAttribute('fill', 'red');
          boothElement.setAttribute('cursor', 'not-allowed');
        } else {
          boothElement.setAttribute('fill', 'green');
          boothElement.setAttribute('cursor', 'pointer');
        }
      }
    });
  }}
/>



      
    </div>

  </div>
  <PackagesList packages={packages} />

  </div>

);}

export default RegistrationForm;
