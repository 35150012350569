import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home';
import SponsorPage from './SponsorPage';
import EventPage from './EventPage';
import RegisterPage from './RegisterPage';
import AgendaPage from './AgendaPage';
import SpeakerPage from './SpeakerPage';
import React, { useEffect } from 'react';

import SessionsPage from './SessionsPage';
import BlogDetailPage from './BlogDetailPage';

import RegPage from './RegPage';
import ForumRegPage from './ForumRegPage';

import RegPage_event from './RegPage_event';

import RegPage_stage from './RegPage_stage';
import EnquirePage from './EnquirePage';
//import EdugateReg from './edugate_form';
//import EdugateRegEvent from './edugate_form_event';
//import OrderManagement from './OrderManagement'

//<Route path="/regedugate" element={<EdugateReg />} />

//<Route path="/edugateevent" element={<EdugateRegEvent />} />
//<Route path="/viewparticipants" element={<OrderManagement />} />
function App() {
  useEffect(() => {
    // After a delay, remove the landscape orientation class
    setTimeout(() => {
      const landscapeView = document.querySelector('.landscape-view');
      if (landscapeView) {
        landscapeView.style.transform = 'none';
        landscapeView.style.width = 'auto';
        landscapeView.style.height = 'auto';
        landscapeView.style.overflow = 'visible';
        landscapeView.style.position = 'static';
        landscapeView.style.backgroundColor = 'transparent';
        landscapeView.style.zIndex = 'auto';
        landscapeView.style.transition = 'none';
      }
    }, 1000); // Adjust the delay (in milliseconds) as needed
  }, []);
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/SponsorPage" element={<SponsorPage />} />
        <Route path="/EventPage" element={<EventPage />} />
        <Route path="/RegisterPage" element={<RegPage />} />

        <Route path="/AgendaPage" element={<AgendaPage />} />
        <Route path="/SpeakerPage" element={<SpeakerPage />} />

        <Route path="/SessionsPage" element={<SessionsPage />} />
        <Route path="/blogs/:id" element={<BlogDetailPage />} />
      
        <Route path="/RegPage" element={<RegPage />} />
        <Route path="/ForumRegPage" element={<  ForumRegPage/>} />

        
        <Route path="/Regevent" element={<RegPage_event />} />
        <Route path="/Regstage" element={<RegPage_stage />} />
 
        <Route path="/enquire" element={<EnquirePage />} />


        {/* Add more Route components for additional pages */}
      </Routes>
    </Router>
  );
}


export default App;
