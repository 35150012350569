import React, { useState, useEffect } from 'react';
import Countdown from 'react-countdown';
import './CountdownComponent.css';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import axios from 'axios';
import { FaShare } from "react-icons/fa";
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';


const CountdownComponent = () => {
  const [eventDate, setEventDate] = useState(null);
  const [eventName, setEventName] = useState('');
  const [eventStartDate, setEventStartDate] = useState('');

  const theme = createTheme({
    palette: {
      primary: {
        main: "#8B0000"
      },
  
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiKey = process.env.REACT_APP_API_KEY; // Load API key from environment variable
        const response = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/figures', {
          headers: {
            'Content-Type': 'application/json',
            'api-key': apiKey, // Include the API key in the request headers
          },
        });
        const firstRecord = response.data[0];
        const dateParts = firstRecord.date.split('-'); // Assuming format is YYYY-MM-DD
        if (dateParts.length === 3) {
          const year = parseInt(dateParts[0]);
          const month = parseInt(dateParts[1]); // Month is 0-based
          const day = parseInt(dateParts[2]);
          const middle = year + "-" + "0" + month + "-" + "0" + day; //+ "T10:00:00";
          const eventDate = new Date(middle).getTime();
          setEventDate(eventDate);
        }
        setEventName(firstRecord.name);
        setEventStartDate(firstRecord.startDate);
      } catch (error) {
        console.error('Error fetching collection:', error);
      }
    };
  
    fetchData();
  }, []);
  

  const renderer = ({ days, hours, minutes, seconds }) => {
    const formatNumber = (number) => {
      return number < 10 ? `0${number}` : number;
    };

    

    return (
      <div className="countdown-container">
        <div className="background-image-container">
          <img
            src="https://edugate-eg.com/wp-content/uploads/2023/09/1024-scaled.webp"
            alt="Event Background"
            className="background-image"
          />
          <div className="countdown-overlay">
            <div className="countdown-card">
              
              <h2 className="countdown-header">Upcoming Event - Stay Tuned!</h2>
              <div className="countdown">
                <div className="countdown-item">
                  <div className={`countdown-circle ${days === 0 && 'flipped'}`}>
                    {formatNumber(days)}
                  </div>
                  <span>Days</span>
                </div>
                <div className="countdown-item">
                  <div className={`countdown-circle ${hours === 0 && 'flipped'}`}>
                    {formatNumber(hours)}
                  </div>
                  <span>Hours</span>
                </div>
                <div className="countdown-item">
                  <div className={`countdown-circle ${minutes === 0 && 'flipped'}`}>
                    {formatNumber(minutes)}
                  </div>
                  <span>Minutes</span>
                </div>
                <div className="countdown-item">
                  <div className={`countdown-circle ${seconds === 0 && 'flipped'}`}>
                    {formatNumber(seconds)}
                  </div>
                  <span>Seconds</span>
                </div>
              </div>

              <div className='countdown-buttons'>
  <ThemeProvider theme={theme}>
    <div style={{ marginBottom: '10px' }}>
      <a style={{ textDecoration: 'none' }} href="/RegisterPage">
        <Button variant="contained">Register Now!</Button>
      </a>
      <a style={{margin:"10px"}}
                  href={`https://api.whatsapp.com/send?text=Join%20us%20for%20the%20${encodeURIComponent(eventName)}%20on%20${encodeURIComponent(eventStartDate)}%20More%20details%20here:%20https://edutech-eg.com/`}
                  target="_blank"
                >
                  
                  <Button variant="contained"><FaShare style={{ color: 'white' }} /> {" "}Share Event </Button>

                </a>
    </div>

    <div>
      <AddToCalendarButton
        name={eventName} // Use the fetched event name
        startDate={eventStartDate} // Use the fetched event start date
        options={['Apple', 'Google']}
        trigger="click"
  inline
  listStyle="modal"
      ></AddToCalendarButton>
               
    </div>
  </ThemeProvider>
</div>


            </div>
          </div>
        </div>
      </div>
    );
  };

  return eventDate !== null ? (
    <Countdown date={eventDate} renderer={renderer} />
  ) : (
    <div>     
        <ThemeProvider theme={theme}>

       <CircularProgress  />
       </ThemeProvider>

    </div> // You can replace this with a loading spinner or message
  );
};

export default CountdownComponent;
