import React from 'react';
import './VideoCard.css'; // Import your custom styles

const VideoCard = ({ video }) => {
  return (
    <div className="video-card">
      <iframe
        title={video.title}
        src={video.videoUrl.replace('watch?v=', 'embed/')}
        frameBorder="0"
        allowFullScreen
      ></iframe>
      <h3 className="video-title">{video.title}</h3>
      <p className="video-year">Year: {video.year}</p>
    </div>
  );
};

export default VideoCard;
